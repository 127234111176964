<template>
  <div class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>รายงานการเงิน</strong>
    </div>
    <AccountFinanceReport :accountId="null" />
  </div>
</template>
<script>
import AccountFinanceReport from '@/views/account-report/components/AccountFinanceReport'

export default {
  name: 'AccountFinance',
  components: {
    AccountFinanceReport
  }
}
</script>
